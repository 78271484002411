@charset "UTF-8";
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  CTA buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
  Text colors
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #374785 !important;
}

.text-theme-secondary {
  color: #bbc5ef !important;
}

/* ----------------------------------------------------------------------------
  Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #374785 !important;
}

.bg-theme-secondary {
  background-color: #bbc5ef !important;
}

.bg-theme-gradient {
  background: linear-gradient(30deg, #3f4f8e, #6f7eb9 42%, #a5add1);
}

.bg-grey-light {
  background: #f6f9fc;
}

.bg-grey-med {
  background: #e9ecef;
}

.bg-theme-dark {
  background: #303444;
}

/* ----------------------------------------------------------------------------
  Borders
----------------------------------------------------------------------------- */
.border-theme-primary {
  border-color: #374785 !important;
}

.border-top-red {
  border-top: 2px solid #e76d64;
}

/* ----------------------------------------------------------------------------
    Font Icons
----------------------------------------------------------------------------- */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot?21099076");
  src: url("../fonts/icons.eot?21099076#iefix") format("embedded-opentype"), url("../fonts/icons.woff2?21099076") format("woff2"), url("../fonts/icons.woff?21099076") format("woff"), url("../fonts/icons.ttf?21099076") format("truetype"), url("../fonts/icons.svg?21099076#icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-dribbble:before {
  content: "\e801";
}

.icon-instagram:before {
  content: "\e802";
}

.icon-behance:before {
  content: "\e803";
}

.icon-linkedin:before {
  content: "\e804";
}

.icon-facebook:before {
  content: "\e805";
}

.icon-twitter:before {
  content: "\e806";
}

.icon-gplus:before {
  content: "\e807";
}

.icon-pinterest:before {
  content: "\e808";
}

.icon-tumblr:before {
  content: "\e809";
}

.icon-down:before {
  content: "\e810";
}

.icon-left:before {
  content: "\e811";
}

.icon-right:before {
  content: "\e812";
}

.icon-up:before {
  content: "\e813";
}

.icon-left-small:before {
  content: "\e814";
}

.icon-right-small:before {
  content: "\e815";
}

.icon-up-small:before {
  content: "\e816";
}

.icon-down-small:before {
  content: "\e817";
}

.icon-down-thin:before {
  content: "\e818";
}

.icon-left-thin:before {
  content: "\e819";
}

.icon-right-thin:before {
  content: "\e820";
}

.icon-up-thin:before {
  content: "\e821";
}

.icon-down-arrow:before {
  content: "\e822";
}

.icon-left-arrow:before {
  content: "\e823";
}

.icon-right-arrow:before {
  content: "\e824";
}

.icon-up-arrow:before {
  content: "\e825";
}

.icon-menu:before {
  content: "\e826";
}

.icon-home:before {
  content: "\e827";
}

.icon-lock:before {
  content: "\e828";
}

.icon-phone:before {
  content: "\e829";
}

.icon-chat:before {
  content: "\e830";
}

.icon-mail:before {
  content: "\e831";
}

.icon-comment:before {
  content: "\e832";
}

.icon-at:before {
  content: "\e833";
}

.icon-star:before {
  content: "\e834";
}

.icon-search:before {
  content: "\e835";
}

.icon-zoom:before {
  content: "\e836";
}

.icon-cog:before {
  content: "\e837";
}

.icon-link:before {
  content: "\e838";
}

.icon-share:before {
  content: "\e839";
}

.icon-check:before {
  content: "\e840";
}

.icon-cancel:before {
  content: "\e841";
}

.icon-plus:before {
  content: "\e842";
}

.icon-minus:before {
  content: "\e843";
}

.icon-close:before {
  content: "\e844";
}

.icon-user:before {
  content: "\e845";
}

.icon-spinner:before {
  content: "\e846";
}

.icon-youtube:before {
  content: "\e847";
}

a.social {
  display: inline-block;
  height: 50px;
  width: 50px;
  margin: 5px;
  color: #fff !important;
  background-color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 22px;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
  border: 0 !important;
  border-radius: 30px;
  transition: all 0.15s linear 0s;
}

a.social [class^=icon-]:before {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
}

a.social:hover,
a.social:active {
  font-size: 24px;
  border-radius: 4px;
  /*transform: rotate(360deg);*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a.social.dribbble:hover,
a.social.dribbble:active {
  background-color: #ea4c89;
}

a.social.instagram:hover,
a.social.instagram:active {
  background-color: #49789d;
}

a.social.behance:hover,
a.social.behance:active {
  background-color: #1769ff;
}

a.social.facebook:hover,
a.social.facebook:active {
  background-color: #3f5495;
}

a.social.twitter:hover,
a.social.twitter:active {
  background-color: #5d87c4;
}

a.social.linkedin:hover,
a.social.linkedin:active {
  background-color: #2575b2;
}

a.social.gplus:hover,
a.social.gplus:active {
  background-color: #cc4436;
}

a.social.pinterest:hover,
a.social.pinterest:active {
  background-color: #c2142f;
}

a.social.tumblr:hover,
a.social.tumblr:active {
  background-color: #529ecc;
}

a.social.youtube:hover,
a.social.youtube:active {
  background-color: #ff0000;
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-300.woff") format("woff"), url("../fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-regular.woff") format("woff"), url("../fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600.woff") format("woff"), url("../fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-600italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-600italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700.woff") format("woff"), url("../fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/open-sans-v18-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("../fonts/open-sans-v18-latin-700italic.ttf") format("truetype"), url("../fonts/open-sans-v18-latin-700italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* fjalla-one-regular - latin */
@font-face {
  font-family: "Fjalla One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/fjalla-one-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/fjalla-one-v8-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/fjalla-one-v8-latin-regular.woff2") format("woff2"), url("../fonts/fjalla-one-v8-latin-regular.woff") format("woff"), url("../fonts/fjalla-one-v8-latin-regular.ttf") format("truetype"), url("../fonts/fjalla-one-v8-latin-regular.svg#FjallaOne") format("svg"); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-regular.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-regular.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-regular.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-regular.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-600.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-600.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-600.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-600.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-slab-v13-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-slab-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-slab-v13-latin-700.woff2") format("woff2"), url("../fonts/roboto-slab-v13-latin-700.woff") format("woff"), url("../fonts/roboto-slab-v13-latin-700.ttf") format("truetype"), url("../fonts/roboto-slab-v13-latin-700.svg#RobotoSlab") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin-300italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-300italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin-italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin-500italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-500italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin-600italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-600italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin-700italic.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-700italic.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIcons-Regular.woff2") format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsOutlined-Regular.otf") format("woff2");
}
.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/MaterialIconsRound-Regular.otf") format("woff2");
}
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-osx-font-smoothing: grayscale;
}

/* ----------------------------------------------------------------------------
    CSS Variable, needs to be set in :root
----------------------------------------------------------------------------- */
:root {
  --font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* ----------------------------------------------------------------------------
    Smooth Scrolling
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
[id] {
  scroll-margin-top: 1rem;
}

/* ----------------------------------------------------------------------------
    Typography
----------------------------------------------------------------------------- */
body {
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: var(--font-family);
  line-height: 1.4;
  color: #374785;
}

h1,
.h1 {
  font-size: 1.602em;
}

h2,
.h2 {
  font-size: 1.424em;
}

h3,
.h3 {
  font-size: 1.266em;
}

h4,
.h4 {
  font-size: 1.125em;
}

h5,
.h5 {
  font-size: 1em;
}

@media (min-width: 48em) {
  /* Medium devices (tablets, 768px and up) */
  h1,
  .h1 {
    font-size: 1.8em;
  }
  h2,
  .h2 {
    font-size: 1.55em;
  }
  h3,
  .h3 {
    font-size: 1.32em;
  }
  h4,
  .h4 {
    font-size: 1.125em;
  }
  h5,
  .h5 {
    font-size: 1em;
  }
}
@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  h1,
  .h1 {
    font-size: 2.074em;
  }
  h2,
  .h2 {
    font-size: 1.728em;
  }
  h3,
  .h3 {
    font-size: 1.44em;
  }
  h4,
  .h4 {
    font-size: 1.2em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
@media (min-width: 75em) {
  /* Extra large devices (large desktops, 1200px and up) */
  h1,
  .h1 {
    font-size: 2.441em;
  }
  h2,
  .h2 {
    font-size: 1.953em;
  }
  h3,
  .h3 {
    font-size: 1.563em;
  }
  h4,
  .h4 {
    font-size: 1.25em;
  }
  h5,
  .h5 {
    font-size: 1.1em;
  }
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

a {
  color: #546bc5;
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: dotted;
  border-bottom-color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #3a51ac;
}

a.img:hover,
a.img:active {
  text-decoration: none;
  border: 0;
}

a[href^="tel:"] {
  color: inherit;
  border: 0;
}

ul.nav,
ol.nav {
  margin-left: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

.cfix:after,
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

#content,
#main_content,
.container,
.container-fluid,
.break_word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------------------------------------------------
    Forms Elements
----------------------------------------------------------------------------- */
.opacity-1 {
  opacity: 1 !important;
}

.display-none {
  display: none !important;
}

/* ----------------------------------------------------------------------------
    Buttons
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Tables
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Modals
----------------------------------------------------------------------------- */
.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header .close {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-top: -0.7rem;
}

.modal-body .btn-close {
  opacity: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  border-bottom-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

/* ----------------------------------------------------------------------------
  Dropdown List
----------------------------------------------------------------------------- */
.dropdown-list {
  margin: auto;
  width: auto;
  position: relative;
  box-sizing: border-box;
}
.dropdown-list li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-list a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.dropdown-list span.dropdown {
  display: block;
  margin: 0;
  padding: 0 10px;
  width: auto;
  height: 40px;
  line-height: 38px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #a1c4e5;
  cursor: pointer;
  overflow: hidden;
}
.dropdown-list span.dropdown.is-active {
  border-radius: 3px 3px 0 0;
}
.dropdown-list span.dropdown:hover, .dropdown-list span.dropdown:active {
  background: #f1f1f1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.dropdown-list span.dropdown:after {
  float: right;
  margin-top: 0;
  margin-left: 10px;
  width: 15px;
  height: 100%;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list span.dropdown.is-active:after {
  content: "\e816";
}
.dropdown-list .drop {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  float: left;
  width: 100%;
  max-height: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  overflow-y: auto;
  opacity: 0;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list .drop li {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  border: 0;
  height: auto;
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li:first-child {
  border-top: 0;
}
.dropdown-list .drop li a {
  float: none;
  display: block;
  margin: 0;
  padding: 6px 10px 6px 25px;
  height: auto;
  width: auto;
  text-transform: none;
  color: inherit;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 0;
  transition: padding-left 0.25s cubic-bezier(0, 0.7, 0, 1);
}
.dropdown-list .drop li a:hover, .dropdown-list .drop li a:active {
  background: #f1f1f1;
}
.dropdown-list .drop li a.no-carets {
  padding-left: 9px;
  font-weight: 600;
}
.dropdown-list .drop li a:not(.no-carets):before {
  display: inline-block;
  margin-left: -15px;
  margin-right: 5px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
.dropdown-list .drop li span.submenu.level-2 {
  display: block;
  margin: 0;
  padding: 6px 30px 6px 9px;
  position: relative;
  font-weight: 600;
  text-align: left;
}
.dropdown-list .drop li span.submenu.level-2:after {
  display: block;
  width: 20px;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  position: absolute;
  right: 10px;
  top: 5px;
}
.dropdown-list .drop li span.submenu.level-2.is-active:after {
  content: "\e816";
}
.dropdown-list .drop li span.submenu.level-2.is-active, .dropdown-list .drop li span.submenu.level-2:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.dropdown-list .drop li span.submenu.level-2.is-active + ul.submenu {
  max-height: 500px;
  background-color: #ffffff;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.dropdown-list .drop li ul.submenu {
  padding-left: 0;
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.3s cubic-bezier(0, 0.6, 0, 1), opacity 0.3s cubic-bezier(0, 0.6, 0, 1);
}
.dropdown-list .drop li ul.submenu li:first-child {
  border-top: 1px dotted #ccc;
}
.dropdown-list .drop li ul.submenu li a {
  padding-left: 33px;
  font-weight: normal;
}
.dropdown-list .drop li ul.submenu li a:hover {
  background-color: #fbfbfb;
  padding-left: 35px;
}
.dropdown-list span.is-active + .drop {
  max-height: 350px;
  border: 1px solid #a1c4e5;
  border-top: 0;
  opacity: 1;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.dropdown-list.dropdown-cta {
  display: inline-block;
  width: auto;
}
.dropdown-list.dropdown-cta span.dropdown {
  padding: 0.5rem 1rem;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: #bbc5ef;
  border-color: #bbc5ef;
  border-radius: 0.3rem;
  font-family: var(--font-family);
  line-height: 1.5;
  text-align: left;
}
.dropdown-list.dropdown-cta span.dropdown:after {
  font-weight: 400;
}
.dropdown-list.dropdown-cta span.dropdown:hover {
  background-color: #d0d7f4;
  border-color: #e4e8f9;
}
.dropdown-list.dropdown-cta .drop {
  top: calc(100% - 3px);
  max-width: 370px;
  width: calc(100vw - 30px);
  border-radius: 0 3px 3px 3px;
}
.dropdown-list.dropdown-cta .drop-lp {
  max-width: 235px;
}
.dropdown-list.dropdown-cta .drop-lp li span {
  text-align: left;
}
.dropdown-list.dropdown-cta span.is-active + .drop {
  max-height: 450px;
  border: 1px solid #bbc5ef;
}
.dropdown-list.drop-up span.dropdown:after {
  content: "\e816";
}
.dropdown-list.drop-up span.dropdown.is-active {
  border-radius: 0 0 3px 3px !important;
}
.dropdown-list.drop-up span.dropdown.is-active:after {
  content: "\e817";
}
.dropdown-list.drop-up.dropdown-cta span.dropdown + .drop {
  top: auto;
  bottom: 45px;
}
.dropdown-list.drop-up .drop {
  top: unset !important;
  bottom: 50px;
  border-top: 1px solid #a1c4e5 !important;
  border-radius: 3px 3px 0 0;
}

@media (min-width: 992px) {
  .dropdown-list.drop-up.drop-up-sm span.dropdown:after {
    content: "\e817";
  }
  .dropdown-list.drop-up.drop-up-sm span.dropdown.is-active:after {
    content: "\e816";
  }
  .dropdown-list.drop-up.drop-up-sm.dropdown-cta span.dropdown + .drop {
    top: 40px;
    bottom: auto;
  }
  .dropdown-list.drop-up.drop-up-sm .drop {
    top: 39px;
    bottom: auto;
    border-bottom: 1px solid #a1c4e5 !important;
    border-radius: 0 0 3px 3px;
  }
}
ul,
ol {
  padding-left: 30px;
}

ul li,
ol li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

ul.check,
ul.star,
ul.carrot {
  margin-left: 0;
  padding-left: 10px;
  list-style: none outside;
  line-height: inherit;
}

ul.check li,
ul.star li,
ul.carrot li {
  padding-left: 1.3em;
  margin-left: 0;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  position: relative;
}

ul.check li li,
ul.star li li,
ul.carrot li li {
  padding-left: 0;
}

ul.carrot li {
  padding-left: 0.9em;
}

ul.check > li:before,
ul.star > li:before,
ul.carrot > li:before {
  display: block;
  margin-top: 0.1em;
  width: 1em;
  height: inherit;
  color: #0099ff;
  font-size: 0.9em;
  font-weight: normal !important;
  word-wrap: normal;
  position: absolute;
  left: 0;
}

ul.check > li:before {
  content: "\e840";
  font-family: "icons";
}

ul.star > li:before {
  content: "\e834";
  font-family: "icons";
}

ul.carrot > li:before {
  content: "»";
  font-family: "icons";
  margin-top: 0.2em;
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  width: 1em;
}

/* ----------------------------------------------------------------------------
  Accordion
----------------------------------------------------------------------------- */
.accordion-wrap {
  margin-bottom: 1rem;
}
.accordion-wrap .accordion {
  padding: 0;
  border-bottom: 1px dotted #ccc;
  border-left: 1px dotted #ccc;
  border-right: 1px dotted #ccc;
  overflow: hidden;
}
.accordion-wrap .accordion:first-child {
  border-top: 1px dotted #ccc;
}
.accordion-wrap .accordion .accordion-toggle {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: calc(1em + 15px);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-toggle:before {
  float: right;
  color: inherit;
  content: "\e817";
  font-family: "icons";
  font-size: 1em;
  font-weight: normal !important;
  height: inherit;
  width: 1em;
  margin-right: -1em;
  text-align: right;
}
.accordion-wrap .accordion .accordion-toggle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.accordion-wrap .accordion.is-open > .accordion-content {
  padding-top: 5px;
  opacity: 1;
  transform: scaleY(1);
}
.accordion-wrap .accordion.is-open > .accordion-toggle {
  background-color: rgba(0, 0, 0, 0.02);
  background-color: #374785;
  color: #fff !important;
  margin-bottom: 0.5rem;
}
.accordion-wrap .accordion.is-open > .accordion-toggle h1,
.accordion-wrap .accordion.is-open > .accordion-toggle h2,
.accordion-wrap .accordion.is-open > .accordion-toggle h3,
.accordion-wrap .accordion.is-open > .accordion-toggle h4,
.accordion-wrap .accordion.is-open > .accordion-toggle h5 {
  color: #fff !important;
}
.accordion-wrap .accordion.is-open > .accordion-toggle:before {
  content: "\e816";
}
.accordion-wrap .accordion .accordion-content {
  padding: 0 20px;
  max-height: 0;
  opacity: 0;
  transform-origin: top;
  transition: max-height 0.35s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
}
.accordion-wrap .accordion .accordion-content .accordion {
  border-left: 0;
  border-right: 0;
}
.accordion-wrap .accordion .accordion-content .accordion .accordion-toggle {
  padding-left: 0.75rem;
  padding-right: calc(1em + 0.75rem);
}
.accordion-wrap .accordion .accordion-content .accordion.is-open > .accordion-toggle {
  background-color: rgba(0, 0, 0, 0.02);
  color: #444 !important;
  margin-bottom: 0.75rem;
}

/* ----------------------------------------------------------------------------
  Bootstrap Overrides
----------------------------------------------------------------------------- */
b,
strong {
  font-weight: 600;
}

.modal-header {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5 {
  margin-top: 0;
  font-weight: 400;
}

input[type=number] {
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: #e0e0e0 !important;
}

.custom-control.is-invalid .custom-control-input ~ .custom-control-label, .custom-control:invalid .custom-control-input ~ .custom-control-label {
  color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input ~ .custom-control-label:before, .custom-control:invalid .custom-control-input ~ .custom-control-label:before {
  border-color: #fb4f16;
}
.custom-control.is-invalid .custom-control-input:focus ~ .custom-control-label:before, .custom-control:invalid .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-control.custom-checkbox .custom-control-label:before, .custom-control.custom-checkbox .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}
.custom-control.custom-radio {
  padding-left: 1.75rem;
}
.custom-control.custom-radio .custom-control-label:before, .custom-control.custom-radio .custom-control-label:after {
  left: -1.75rem;
  width: 1.2rem;
  height: 1.2rem;
}
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  border-color: #316ce8;
  background-color: #316ce8;
}

.form-control.is-invalid {
  border-color: #fb4f16;
}
.form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  background-image: none;
}

.custom-select.is-invalid {
  border-color: #fb4f16;
}
.custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #80bdff;
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
}

.table {
  word-wrap: normal;
  word-break: normal;
}
.table.align-middle th,
.table.align-middle td {
  vertical-align: middle;
}
.table.table-sm td,
.table.table-sm th {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.list-group-item {
  margin-top: 0;
  margin-bottom: 0;
}

/* ----------------------------------------------------------------------------
  ASC Default stuff
----------------------------------------------------------------------------- */
.price {
  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  color: #555;
  text-align: center;
  font: bold 0 "Trebuchet MS", "Droid Serif", Arial, sans-serif;
  overflow: visible;
  vertical-align: middle;
}

.price.static {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.price.shown {
  color: #555;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price.ribbon {
  margin-top: -6px;
  margin-bottom: 0;
  padding: 28px 0 0 0;
  width: 140px;
  height: 93px;
  color: #fff;
  background: url(../images/price-bg.png) no-repeat 0 0;
}

.price.burst {
  padding: 60px 0 0 0;
  width: 155px;
  height: 155px;
  color: #fff;
  background: url(../images/price-bg-burst.png) no-repeat 50% 50%;
}
.price.burst.spanish {
  background: url(../images/price-bg-burst-spanish.png) no-repeat 50% 50%;
}

.price.ribbon.static,
.price.burst.static {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price.ribbon.shown,
.price.burst.shown {
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  transition: color ease-in-out 0.1s, text-shadow ease-in-out 0.1s;
}

.price span.dollar-sign,
.price span.cents,
.price span.cents-fees,
.price span.only,
.price span.asterisk {
  display: inline-block;
  margin: 5px 0 0 0;
  font-size: 30px;
  line-height: 30px;
  vertical-align: top;
}

.price span.only {
  margin-bottom: 5px;
  font-size: 26px;
  display: block;
}

.price span.dollars,
.price span.dollars-fees {
  display: inline-block;
  margin-left: -1px;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1px;
  vertical-align: middle;
}

.price span.cents,
.price span.cents-fees {
  margin: 4px 0 0 3px;
  letter-spacing: -1px;
}

.price.shown span.reg-price {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  text-decoration: line-through;
  padding-left: 3px;
  vertical-align: middle;
  text-shadow: none;
}

.price.ribbon span.reg-price {
  color: #eee;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.price span.dollar-sign {
  filter: blur(10px);
}

.price.shown span.dollar-sign {
  filter: blur(0);
}

.price span.dollars:after,
.price span.dollars-fees:after,
.price span.cents:after,
.price span.cents-fees:after,
.price span.dollars:after,
.price span.cents:after {
  display: inline-block;
  content: "##";
  text-shadow: 0 0 20px currentcolor;
  filter: blur(10px);
}

.price.shown span.dollars:after,
.price.shown span.dollars-fees:after,
.price.shown span.cents:after,
.price.shown span.cents-fees:after,
.price.shown span.dollars:after,
.price.shown span.cents:after {
  display: none;
  filter: blur(0);
}

/* -- for dynamic LGAs -- */
.lga-item {
  display: none;
  opacity: 0;
}
.lga-item.lga-replaced {
  display: inline;
  opacity: 1;
}

/* -- Customize Bootstrap Utilities-- */
.w-auto {
  max-width: 100% !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: 100% !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-auto {
    width: 100% !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-auto {
    width: 100% !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-auto {
    width: 100% !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.ls-10 {
  letter-spacing: 0.1rem;
}

.ls-15 {
  letter-spacing: 0.15rem;
}

.ls-20 {
  letter-spacing: 0.2rem;
}

.ls-25 {
  letter-spacing: 0.25rem;
}

.lh-1 {
  line-height: 1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-14 {
  line-height: 1.4;
}

.lh-16 {
  line-height: 1.6;
}

.lh-18 {
  line-height: 1.8;
}

.lh-20 {
  line-height: 2;
}

.lh-25 {
  line-height: 2.5;
}

/* ----------------------------------------------------------------------------
  Navigation Menu
----------------------------------------------------------------------------- */
#nav-wrap {
  background: #374785;
}

#menutoggle {
  display: block;
  margin: 0;
  padding: 0 18px;
  width: auto;
  color: #fff;
  background: #374785;
  height: 50px;
  font-size: 18px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.35);
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  border-radius: 4px 4px 0 0;
  transition: background 0.2s linear 0s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#menutoggle span {
  display: none;
}
#menutoggle .hamburger-box {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;
  vertical-align: middle;
}
#menutoggle .hamburger-box .hamburger-inner {
  display: block;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
#menutoggle .hamburger-box .hamburger-inner,
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, width;
  border-radius: 4px;
  background-color: #fff;
  right: 0;
}
#menutoggle .hamburger-box .hamburger-inner {
  top: 0;
  width: 25px;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  width: 22px;
}
#menutoggle .hamburger-box .hamburger-inner::after,
#menutoggle .hamburger-box .hamburger-inner::before {
  display: block;
  content: "";
}
#menutoggle .hamburger-box .hamburger-inner::before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform, opacity;
}
#menutoggle .hamburger-box .hamburger-inner::after {
  top: 20px;
}
#menutoggle.is-active .hamburger-inner,
#menutoggle.is-active .hamburger-inner::after,
#menutoggle.is-active .hamburger-inner::before {
  width: 30px;
}
#menutoggle.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
#menutoggle.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
#menutoggle.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
#menutoggle:hover {
  background-color: white;
}
#menutoggle:hover .hamburger-box .hamburger-inner,
#menutoggle:hover .hamburger-box .hamburger-inner::after,
#menutoggle:hover .hamburger-box .hamburger-inner::before {
  width: 30px;
}
#menutoggle:hover, #menutoggle:active, #menutoggle.is-active {
  background-color: #4255a0;
  border-radius: 4px 4px 0 0;
}
#menutoggle.is-active + ul {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out, opacity 0.25s ease-in-out;
}

#nav {
  clear: both;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  position: relative;
  z-index: 1000;
  border-top: 1px solid #374785;
}
#nav ul {
  float: none;
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  opacity: 0;
  max-height: 0;
  background: #374785;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0, 0.7, 0, 1), opacity 0.2s ease-in-out;
  border-radius: 0 0 4px 4px;
  top: 1px;
  border: 0;
  position: relative;
}
#nav ul.submenu {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 0;
  background: none;
  opacity: 1;
  position: relative;
  top: 0;
  transition: max-height 0.15s linear 0s;
}
#nav ul.submenu a {
  padding-left: 40px;
  background: none;
}
#nav ul.submenu a:before {
  display: inline-block;
  margin-right: 5px;
  margin-left: -15px;
  width: 10px;
  height: inherit;
  content: "»";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: inherit;
  vertical-align: top;
}
#nav li {
  display: block;
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  border: 0;
  border-top: 1px dotted #4255a0;
}
#nav li:first-child {
  border-top: 0;
}
#nav li.onmobile {
  display: block;
}
#nav a,
#nav span.submenu {
  float: none;
  display: block;
  margin: 0;
  padding: 9px 20px;
  height: auto;
  color: #fff;
  line-height: 1.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: background 0.17s linear 0s;
}
#nav span.submenu:after,
#nav a.submenu:after {
  float: right;
  margin-left: 10px;
  width: 15px;
  height: inherit;
  content: "\e817";
  color: inherit;
  font-family: "icons";
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#nav span.submenu.is-active,
#nav a.submenu.is-active {
  border-bottom: 1px dotted #4255a0;
}
#nav span.submenu.is-active + ul.submenu,
#nav a.submenu.is-active + ul.submenu {
  max-height: 360px;
  overflow-y: auto;
}
#nav span.submenu.is-active:after,
#nav a.submenu.is-active:after {
  content: "\e816";
}
#nav a:hover,
#nav a.is-active,
#nav li:hover span.submenu,
#nav li:hover a.submenu,
#nav ul.submenu a:hover,
#nav ul.submenu a.is-active {
  background: #4255a0;
}

@media (min-width: 36em) {
  /* Small devices (landscape phones, 576px and up) */
  /*@media (min-width: 48em) { /* Medium devices (tablets, 768px and up) */
  #menutoggle {
    padding: 0 15px 0 15px;
  }
  #menutoggle span {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  #menutoggle:before {
    margin-right: 10px;
  }
}
@media (min-width: 1200px) {
  /* Large devices (desktops, 1200px and up) */
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }
  #nav {
    height: 45px;
    background: #374785;
    position: relative;
    z-index: 99;
    border: 0;
    border-radius: 4px;
  }
  #nav ul {
    display: block !important;
    float: left;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }
  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #4255a0;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s, opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }
  #nav ul.submenu.right {
    border-radius: 3px 0 3px 3px;
    right: 0;
  }
  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }
  #nav ul.submenu li:first-child a {
    border: 0;
  }
  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #374785;
  }
  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }
  #nav li:first-child {
    margin-left: 0;
    border-left: 0;
  }
  #nav li.last {
    border-right: 0;
  }
  #nav li.right {
    float: right;
    margin-right: 0;
    border-right: 0;
  }
  #nav li.onmobile {
    display: none;
  }
  #nav li:hover {
    z-index: 11;
  }
  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 14px;
    height: 37px;
    border: 0;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s linear 0s;
    cursor: pointer;
  }
  #nav span.submenu:after,
  #nav a.submenu:after {
    display: none;
  }
  #nav a:hover,
  #nav a.is-active,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav li:hover a.submenu,
  #nav ul.submenu a:hover,
  #nav span.submenu.is-active,
  #nav a.submenu.is-active {
    background: #4255a0;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav li:hover a.submenu,
  #nav span.submenu.is-active.hover,
  #nav a.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
  }
  #nav li:hover ul.submenu.right,
  #nav ul.submenu.is-active.right {
    left: auto;
    right: 0;
  }
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #374785;
  }
}
/* ----------------------------------------------------------------------------
	Buttons
----------------------------------------------------------------------------- */
.cta-btn {
  display: inline-block;
  padding: 10px 0 8px 0;
  color: #fff;
  background-color: #555555;
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 220px;
  line-height: 1.35;
  font-size: 20px;
  font-weight: normal;
  font-family: "Fjalla One", Impact, Verdana;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
  outline: 0;
  overflow: hidden;
  transition: background-color 0.1s ease-in-out 0.05s;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.cta-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #3c3c3c 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}
.cta-btn:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.cta-btn.fancy {
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn:hover,
.cta-btn:active {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #434343;
  border: 0;
}

.cta-btn.big {
  padding: 13px 0 10px 0;
}

.cta-btn.big.outline {
  padding: 12px 0 9px 0;
}

.cta-btn.small {
  padding: 9px 0 7px 0;
  font-size: 18px;
}

.cta-btn.small.fancy {
  line-height: 41px;
}

.cta-btn.small.outline {
  padding: 8px 0 6px 0;
}

.cta-btn.dark {
  color: #fff;
  background: #555555;
}

.cta-btn.orange {
  color: #fff;
  background: #ff6633;
}

.cta-btn.yellow {
  color: #333;
  background: #ffc107;
}

.cta-btn.green {
  color: #fff;
  background: #5cb865;
}

.cta-btn.red {
  color: #fff;
  background: #e76d64;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.75);
}

.cta-btn.blue {
  color: #fff;
  background: #0099ff;
}

.cta-btn[class~=outline] {
  border: 1px solid #555555;
}

.cta-btn.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.dark.outline {
  color: #555555;
  background: transparent;
  border-color: #555555;
}

.cta-btn.orange.outline {
  color: #ff6633;
  background: transparent;
  border-color: #ff6633;
}

.cta-btn.green.outline {
  color: #5cb865;
  background: transparent;
  border-color: #5cb865;
}

.cta-btn.red.outline {
  color: #e76d64;
  background: transparent;
  border-color: #e76d64;
}

.cta-btn.yellow.outline {
  color: #ffc107;
  background: transparent;
  border-color: #ffc107;
}

.cta-btn.blue.outline {
  color: #0099ff;
  background: transparent;
  border-color: #0099ff;
}

.cta-btn.pill {
  border-radius: 100px;
}

.cta-btn.dark.fancy {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  background: #555555;
  background-image: linear-gradient(dimgray, #3c3c3c);
}

.cta-btn.dark:hover,
.cta-btn.dark:active {
  background: #434343;
}

.cta-btn.outline:hover,
.cta-btn.outline:active,
.cta-btn.dark.outline:hover,
.cta-btn.dark.outline:active {
  background: rgba(85, 85, 85, 0.1);
}

.cta-btn.orange.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  background: #ff6633;
  background-image: linear-gradient(#ff855c, #ff531a);
}

.cta-btn.orange:hover,
.cta-btn.orange:active {
  background: #ff4b0f;
}

.cta-btn.orange.outline:hover,
.cta-btn.orange.outline:active {
  background: rgba(255, 102, 51, 0.1);
}

.cta-btn.green.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #5cb865;
  background-image: linear-gradient(#78c480, #4cae56);
  color: #fff;
}

.cta-btn.green:hover,
.cta-btn.green:active {
  background: #49a752;
}

.cta-btn.green.outline:hover,
.cta-btn.green.outline:active {
  background: rgba(92, 184, 101, 0.1);
}

.cta-btn.red.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #e76d64;
  background-image: linear-gradient(#ec8e87, #e4584e);
}

.cta-btn.red:hover,
.cta-btn.red:active {
  background: #e25045;
}

.cta-btn.red.outline:hover,
.cta-btn.red.outline:active {
  background: rgba(231, 109, 100, 0.1);
}

.cta-btn.blue.fancy {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #0099ff;
  background-image: linear-gradient(#29a9ff, #008ae6);
}

.cta-btn.blue:hover,
.cta-btn.blue:active {
  background: #24a7ff;
}

.cta-btn.blue.outline:hover,
.cta-btn.blue.outline:active {
  background: rgba(0, 153, 255, 0.1);
}

.cta-btn.yellow.fancy {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background: #ffc107;
  background-image: linear-gradient(#ffce3a, #edb100);
}

.cta-btn.yellow:hover,
.cta-btn.yellow:active {
  background: #ffca2b;
}

.cta-btn.yellow.outline:hover,
.cta-btn.yellow.outline:active {
  background: rgba(255, 193, 7, 0.1);
}

.cta-btn.disabled {
  background: #999 !important;
  pointer-events: none;
}

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #444;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1356px;
}

@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
}
.footer p {
  margin: 0 0 5px 0;
}
.footer p.trustmark {
  display: none;
  margin: 0;
}
.footer a {
  color: #555;
}
.footer .social a {
  border: 0;
}
.footer img {
  display: inline-block;
}
.footer #trustwaveSealImage {
  margin: 0 10px 30px 0;
}

#footer-info {
  border-bottom: 1px solid #ccc;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
}
#header a.logo {
  border: 0;
  width: 70%;
  max-width: 375px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#header a.logo img {
  margin-right: 0;
  margin-bottom: 7px;
  width: 100%;
  height: auto;
  max-width: 335px;
}
#header a.logo span.small {
  display: block;
  color: #555;
  line-height: 1em;
  margin-top: 0;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: normal;
}
#header #support p {
  margin: 2px 12px 0 0;
}
#header #support p strong {
  font-size: 16px;
}
#header #support a.login {
  padding: 0 12px 2px 12px;
  color: #fff;
  background: #374785;
  text-decoration: none;
  border-radius: 0 0 4px 4px;
  border: 0;
}
#header #support a.login:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e828";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.login:hover {
  background: #4255a0;
}
#header #support span.phone {
  padding-right: 8px;
  border-right: 1px dotted #333;
}
#header #support a.livechat {
  color: #555;
  border: 0;
}
#header #support a.livechat:before {
  display: inline-block;
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: "\e830";
  color: inherit;
  font-family: "icons";
  font-weight: normal;
  text-align: center;
  font-size: 105%;
  vertical-align: top;
}
#header #support a.livechat:hover:before {
  color: #374785;
}
#header #search {
  float: right;
  clear: right;
  margin: 12px 0;
}
#header #search input[type=search] {
  width: 130px;
  transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
}
#header #search input[type=search]:focus {
  width: 250px;
  background-color: #fff;
}
#header #search input[type=search]::placeholder {
  color: #999 !important;
}
#header input.addsearch {
  background: #f2f2f2 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") no-repeat 9px center;
  padding-left: 35px;
}

#nav form {
  padding: 9px 20px;
}
#nav form label {
  color: #fff;
}
#nav form input {
  margin-bottom: 5px;
  border: 0;
}

#banner-wrap.landing {
  background: #4c62b7 url("../images/banners/banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.landing #banner h2 {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
}
#banner-wrap.landing #banner .details {
  font-size: 1em;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
}
#banner-wrap.boating {
  background: #4c62b7 url("../images/banners/boating-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.defensive {
  background: #4c62b7 url("../images/banners/defensive-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.bus {
  background: #4c62b7 url("../images/banners/bus-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.drivered {
  background: #4c62b7 url("../images/banners/drivered-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.driving-records #banner {
  background: #fff url("../images/banners/banner-bg-dr-sm.jpg") no-repeat 0 bottom;
  background-size: contain;
}
#banner-wrap.hazmat {
  background: #4c62b7 url("../images/banners/hazmat-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.home {
  background: #4c62b7 url("../images/banners/home-banner-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.mature {
  background: #4c62b7 url("../images/banners/mature-banner-bg-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.aaa-pilot {
  background: #4c62b7 url("../images/banners/aaa-pilot-banner-sm.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#banner-wrap.teendriver {
  background: #4c62b7 url("../images/banners/ftd-banner-bg-sm.jpg") no-repeat 50% 25%;
  background-size: cover;
}
#banner-wrap.geico {
  background: #fff url("../images/geico/banners/banner-bg-sm.jpg") no-repeat 50% 25%;
  background-size: cover;
}
#banner-wrap.geico #banner h2 {
  text-shadow: 0 0 0;
}
#banner-wrap.geico #banner .details {
  text-shadow: 0 0 0;
}
#banner-wrap .dropdown-list {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

#icons-wrap span {
  line-height: 1.3em;
  display: inline-block;
  vertical-align: middle;
}
#icons-wrap .icon.bottom,
#icons-wrap .icon.top {
  border-bottom: 1px dotted #ccc;
}
#icons-wrap .border-icon {
  border-bottom: 1px solid #bbc5ef;
}
#icons-wrap .icon.min-125 {
  min-width: 125px;
}

.dropdown-list {
  width: 100%;
  max-width: 300px;
}
.dropdown-list span.dropdown.cta-dropdown {
  min-width: 170px;
  padding: 16px;
  padding-bottom: 14px;
  color: #fff;
  font-weight: 400;
  background: #e76d64;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 20px;
  text-align: left;
  font-family: "Fjalla One", "Open Sans", "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.75);
}
.dropdown-list span.dropdown.cta-dropdown + .drop {
  top: 50px;
}
.dropdown-list span.dropdown.cta-dropdown + .drop.small-cta {
  top: 42px;
}
.dropdown-list span.dropdown.cta-dropdown.small {
  padding: 12px 16px;
  font-size: 18px;
}
.dropdown-list span.dropdown.cta-dropdown:hover, .dropdown-list span.dropdown.cta-dropdown.is-active {
  background: #e4584e;
}

.card.action {
  border-top: 3px solid #374785;
  position: relative;
  z-index: 10;
}
.card.course-display {
  border-top: 3px solid #374785;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease 0s;
}
.card.course-display:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.card.small-price {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #626677;
  max-width: 250px;
}
.card hr {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}

/* BLURB (Most popular and December Special) */
.sales-blurb {
  margin-top: -55px;
  position: absolute;
  width: 100%;
  transform: translate(-50%, 0);
}
.sales-blurb.highlight,
.sales-blurb .highlight {
  color: #0099ff;
  font-weight: normal;
  font-size: 21px;
  font-family: "Fjalla One", Impact, "Open Sans", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.sales-blurb.special {
  margin-top: -75px;
}
.sales-blurb .mt-n1 {
  margin-top: -0.25rem !important;
}

/* RECORD TABLE */
#record-table {
  border: 1px solid #999;
  border-top: 5px solid #999;
  background: #fff;
}
#record-table h2 {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}
#record-table .record a {
  color: #555;
}
#record-table .line {
  padding: 15px;
  border-bottom: 1px dotted #66ccff;
  overflow: hidden;
  font-size: 0;
}
#record-table .line:first-child {
  border-top: 0px;
}
#record-table .line:last-child {
  border-bottom: 0px;
}
#record-table .line:hover {
  background: #e5f6ff;
}
#record-table .record {
  padding: 0 10px;
  color: #555;
  font-size: 24px;
  font-weight: bold;
  width: 40%;
  padding: 0 10px;
  text-align: center;
}
#record-table .pricing {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #555;
  width: 20%;
}
#record-table .link,
#record-table .rates {
  text-align: center;
  font-size: 16px;
  width: 20%;
}
#record-table .link,
#record-table .order {
  margin: 0 auto;
  margin-left: 0;
}
#record-table .order {
  text-align: center;
  margin-left: 20%;
  width: 20%;
}
#record-table .link {
  margin-left: -40%;
}
#record-table .btn {
  width: 100%;
  max-width: 170px;
  height: 35px;
  line-height: 19px;
  font-size: 16px;
}

#record-table .record,
#record-table .pricing,
#record-table .link,
#record-table .rates,
#record-table .order {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  width: 100%;
}

#practice-test ul {
  list-style-type: none;
  padding-left: 0;
}
#practice-test ul input {
  margin-right: 10px;
}
#practice-test .alert p {
  margin-bottom: 0;
}
#practice-test .alert.green {
  background-color: #e9ffdb;
  border: 1px solid #6cb142;
  color: #4e8c28;
}
#practice-test .alert.red {
  background-color: #ffeeee;
  border: 1px solid #ffabab;
  color: #cc0000;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive:before {
  display: block;
  content: "";
  padding-top: 56.25%;
}
.embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.records-wrap-notice {
  background-color: #35b4cf;
  font-weight: 600;
}

.coupon {
  border: 2px dashed #000045;
  background-color: #f0f2f4;
  font-size: 1.3em;
}

.tx-defensive blockquote {
  margin: 10px 0 0 0;
  font: normal 30px/40px Georgia, "Trebuchet MS", Verdana, Tahoma, Helvetica, Arial, sans-serif;
  color: #000;
  font-style: italic;
  border: 0;
}

.fw-semibold {
  font-weight: 600;
}

.border-dotted {
  border-bottom: 1px dotted;
  padding-bottom: 2px;
}

.border-callout {
  border-color: #bbc5ef !important;
}

.alert.alert-warning h1,
.alert.alert-warning h2,
.alert.alert-warning h3,
.alert.alert-warning h4 {
  color: var(--bs-warning-text-emphasis);
}

.mw-150 {
  min-width: 150px;
}

.mw-250 {
  min-width: 250px;
}

.mxw-350 {
  max-width: 350px;
}

.mxw-550 {
  max-width: 550px;
}

.btn-width-xl {
  width: 280px;
  max-width: 280px;
}

.table-wrap {
  overflow-x: scroll;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.z-10 {
  position: relative;
  z-index: 10;
}

/* AAA Pilot */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: fadeIn 0.15s;
  z-index: 99;
}

.aaa-pilot .dropdown-list {
  width: 100%;
  max-width: 230px;
}
.aaa-pilot .cta-dropdown {
  min-width: 170px !important;
}
.aaa-pilot .btn-width-min {
  min-width: 140px;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 576px) {
  #icons-wrap .icon.bottom {
    border-bottom: 0;
  }
  .btn-width {
    width: 220px;
  }
}
@media (min-width: 768px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #banner-wrap.landing {
    background: #4c62b7 url("../images/banners/banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.landing #banner .details {
    font-size: 1.1em;
  }
  #banner-wrap.boating {
    background: #4c62b7 url("../images/banners/boating-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.defensive {
    background: #4c62b7 url("../images/banners/defensive-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.bus {
    background: #4c62b7 url("../images/banners/bus-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.drivered {
    background: #4c62b7 url("../images/banners/drivered-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.hazmat {
    background: #4c62b7 url("../images/banners/hazmat-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.mature {
    background: #4c62b7 url("../images/banners/mature-banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.aaa-pilot {
    background: #4c62b7 url("../images/banners/aaa-pilot-banner-lg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  #banner-wrap.teendriver {
    background: #4c62b7 url("../images/banners/ftd-banner-bg.jpg") no-repeat 100% 50%;
    background-size: cover;
  }
  #banner-wrap.geico {
    background: #fff url("../images/geico/banners/banner-bg.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  .border-md-end {
    border-right: 1px dotted #ccc;
    padding-right: 30px;
  }
  #record-table .record {
    width: 40%;
  }
  #record-table .pricing {
    width: 20%;
  }
  #record-table .order {
    margin-left: 20% !important;
    width: 20%;
  }
  #record-table .link {
    margin-left: -40% !important;
    width: 20%;
  }
  #record-table .rates {
    width: 20%;
  }
  .aaa-pilot .cta-dropdown {
    min-width: 220px !important;
  }
}
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid #374785;
  }
  #header a.logo span.small {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  [class*=container-] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 50%;
  }
  #header a.logo img {
    max-width: 350px;
  }
  #banner-wrap.home {
    background: #fff url("../images/banners/home-banner-md.jpg") no-repeat 110% 50%;
    background-size: contain;
  }
  #banner-wrap.geico #banner {
    background: url("/images/geico/geico-gecko.png") no-repeat 0 bottom;
  }
  #banner-wrap.geico #banner.geico-home {
    background-size: 18%;
  }
  #overlay-banner {
    background: linear-gradient(110deg, rgb(255, 255, 255) 0, rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) 50.1%);
  }
  #icons-wrap .icon.top {
    border: 0;
  }
  #icons-wrap .icon.border-blue-end {
    border-right: 2px solid #374785;
  }
  #icons-wrap .border-icon {
    border-right: 1px solid #bbc5ef;
    border-bottom: 0;
  }
  #icons-wrap .icon.min-125 {
    min-width: auto;
  }
  .tx-defensive #content {
    background: transparent url("../images/banners/tx-defensive.png") no-repeat 0 bottom;
  }
  .tx-defensive blockquote {
    margin: 25px 0 0 0;
  }
  .tx-defensive ul {
    font-size: 1.1em;
  }
  .border-lg-0 {
    border: 0 !important;
  }
}
@media (min-width: 1100px) {
  #banner-wrap.home {
    background-position: 100% 50%;
  }
}
@media (min-width: 1200px) {
  #banner-wrap.geico #banner {
    background-position: 5% bottom;
  }
  .table-wrap {
    overflow-x: hidden;
  }
}
@media (min-width: 1300px) {
  #banner-wrap.home {
    background: #fff url("../images/banners/home-banner-lg.jpg") no-repeat calc(50% + 360px) 50%;
    background-size: contain;
  }
  #banner-wrap.driving-records #banner {
    background: #fff url("../images/banners/banner-bg-dr.jpg") no-repeat 0 0;
    background-size: contain;
  }
  #banner-wrap.driving-records #banner .details {
    font-size: 1.1em;
  }
  #banner-wrap.geico #banner {
    background-position: 7% bottom;
  }
}